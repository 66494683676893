<template>
  <b-modal title="Add giftcard" hide-footer :id="`add-giftcard`">
    <form>
      <b-button-toolbar justify>
        <b-button-group class="w-100">
          <b-button :class="{ active: searchGiftcardsBy === 'id' }" @click="searchGiftcardsBy = 'id'">ID</b-button>
          <b-button :class="{ active: searchGiftcardsBy === 'code' }" @click="searchGiftcardsBy = 'code'">
            Code
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      <b-form-group>
        <MultiSelect
          v-model="selectedGiftcard"
          :options="availableGiftcards"
          placeholder="Search giftcard"
          :label="searchGiftcardsBy"
          :track-by="searchGiftcardsBy"
          @search-change="searchGiftcards"
          @select="setGiftcardSum"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">
                #{{ props.option.id }} ({{ props.option.code }}) - {{ formatMoney(props.option.sum) }}
              </span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">
                #{{ props.option.id }} ({{ props.option.code }}) - {{ formatMoney(props.option.sum) }}
              </span>
            </span>
          </template>
        </MultiSelect>
      </b-form-group>

      <b-form-group v-if="selectedGiftcard">
        <label>How much to add from the giftcard?</label>
        <b-form-input type="number" min="0" :max="order.full_sum / 100" v-model="selectedGiftcardSum" />
      </b-form-group>
      <b-form-group>
        <b-button @click="addGiftcardToOrder()" variant="primary">
          Save
          <div v-if="isLoading" class="spinner-border spinner-border-sm text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </b-button>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { giftcard, orders } from '@/api'
import { formatDate, formatMoney } from '../../page-helpers'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import MultiSelect from 'vue-multiselect'

export default {
  name: 'AddGiftcardModal',
  components: {
    MultiSelect,
  },
  props: {
    order: Object,
  },
  data() {
    return {
      selectedGiftcard: '',
      availableGiftcards: [],
      searchGiftcardsBy: 'id',
      selectedGiftcardSum: 0,
      isLoading: false,
    }
  },
  methods: {
    formatDate,
    formatMoney,

    setGiftcardSum(giftcard) {
      this.selectedGiftcardSum = giftcard.sum.amount / 100
    },

    async searchGiftcards(query) {
      const res = await giftcard.go(1, {
        filter: {
          [this.searchGiftcardsBy]: query,
          open: true,
        },
      })

      this.availableGiftcards = res.data()
    },

    async addGiftcardToOrder() {
      try {
        this.isLoading = true
        const response = await orders.addGiftcard(this.order.id, this.selectedGiftcard.id, this.selectedGiftcardSum * 100)
        this.order.giftcard_id = response.data.giftcard_id
        this.order.giftcard_sum = response.data.giftcard_sum
        this.order.paid = response.data.paid
        this.$emit('triggerUpdate')
        this.$bvModal.hide('add-giftcard')
        this.$bvToast.toast(`Giftcard added to order.`, {
          title: 'Giftcard Added',
          variant: 'primary',
        })
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: 'Invalid Giftcard',
          variant: 'danger',
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
